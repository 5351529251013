import { render, staticRenderFns } from "./checkLiveDetail.vue?vue&type=template&id=0273e906&scoped=true"
import script from "./checkLiveDetail.vue?vue&type=script&lang=js"
export * from "./checkLiveDetail.vue?vue&type=script&lang=js"
import style0 from "./checkLiveDetail.vue?vue&type=style&index=0&id=0273e906&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0273e906",
  null
  
)

export default component.exports